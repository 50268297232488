<template>
    <popup
        dialog-title="Storage King Project"
        dialog-action-button-label="Save"
        :dialog-action-func="clickSave"
        :form-schema="formSchema"
        v-model="formModel"
        :dataSets="{ costCentreLinks }"
    />
</template>

<script>
import api from '@/api';

import dateUtil from '@/../common/utils/date';

import Popup from '@/components/form/Popup';

const formSchema = {
    yearMonth: {
        component: 'date-field',
        label: 'Month',
        cols: 6,
        month: true,
        today: true,
    },
    costCentreLink: {
        component: 'select-field',
        label: 'Cost Centre',
        cols: 6,
        dataSets: { items: 'costCentreLinks' },
    },
};

export default {
    name: 'StorageKingListEdit',
    props: {
        yearMonth: String,
    },
    components: { Popup },
    data() {
        return {
            dialog: false,
            formModel: { yearMonth: '', costCentreLink: '' },
            formSchema,
            costCentreLinks: [],
            costCentreLink: null,
            monthsArray: [],
        };
    },
    methods: {
        clickSave() {
            this.$emit('save', this.formModel);
            this.dialog = false;
        },
        async loadCostCentres() {
            try {
                const costCentreResult = await api.get(this.$store, 'storageking/costcentres');
                this.costCentreLinks = costCentreResult.data.costCentreLinks;
            } catch (e) {
                console.log('loadCostCentres', e.message, e.stack);
                this.$store.dispatch('setError', 'Error loading Cost Centres');
            }
        },
    },
    async created() {
        console.log('projectlistedit created 1');
        await this.loadCostCentres();
        this.monthsArray = dateUtil.monthsArray;
        this.formModel.yearMonth = this.yearMonth;
        console.log('projectlistedit created 2', this.costCentreLinks);
    },
};
</script>
