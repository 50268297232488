<template>
    <Page
        title="Storage King Projects"
        icon="mdi-hospital-building"
    >
        <template v-slot:header>
            <smart-form
                v-model="formModel"
                :schema="formSchema"
                @input="getProjects"
                form-code="projectlist"
                :is-history="isHistory"
                hide-border
                hide-background
                class="my-0"
                style="width: 400px;"
            />
            <toolbar>
                <storage-king-project-list-edit
                    v-if="yearMonth"
                    :yearMonth="yearMonth"
                    @save="clickSave"
                    :key="yearMonth"
                />
            </toolbar>
        </template>

        <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            :sort-by="['yearMonth']"
            :sort-desc="true"
            :items-per-page="5"
            class="elevation-1"
            @click:row="clickRow"
            style="min-width: 500px"
        >
            <template v-slot:top>
                <!--                <v-toolbar flat>-->
                <!--                    <v-spacer />-->
                <!--                </v-toolbar>-->
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
        </v-data-table>
    </Page>
</template>

<script>
import api from '@/api';
import StorageKingProjectListEdit from '@/views/storageKing/components/ProjectListEdit';

const formSchema = {
    yearMonth: {
        component: 'dateField',
        label: 'Project Month',
        month: true,
        cols: 12,
        today: true,
    },
};

export default {
    name: 'StorageKingProjectList',
    components: { StorageKingProjectListEdit },
    data() {
        return {
            tableHeaders: [],
            tableItems: [],
            projects: [],
            loadingMessage: 'Retrieving results.',
            isShowLoading: false,
            isShowError: false,
            showAlert: false,
            dataLoaded: false,
            formModel: {},
            yearMonth: null,
            formSchema,
            isHistory: false,
        };
    },
    methods: {
        async getProjects() {
            this.isShowLoading = true;
            const path = `storageking/project?yearMonth=${this.formModel.yearMonth}`;
            const result = await api.get(this.$store, path);

            if (result.success === false) {
                console.log('error happened', result);
            }
            this.projects = result.data;
            this.yearMonth = this.formModel.yearMonth;
            this.setTableData();
        },
        clickRow(row) {
            this.$router.push(`project/${row.id}`);
        },
        async clickSave(saveItem) {
            const result = await api.post(this.$store, 'storageking/project', saveItem);
            await this.$router.push(`project/${result.data.id}`);
        },
        editItem() {},
        deleteItem() {},
        setTableData() {
            this.tableHeaders = [
                {
                    text: 'Cost Centre',
                    align: 'center',
                    sortable: true,
                    value: 'costCentreCode',
                },
                {
                    text: 'Month',
                    align: 'center',
                    sortable: true,
                    value: 'yearMonth',
                },
            ];
            this.tableItems = this.projects.map((project) => ({
                id: project.id,
                costCentreCode: project.costCentreLink.text,
                yearMonth: project.yearMonth,
            }));

            this.dataLoaded = true;
            this.isShowLoading = false;
            // set the headers and column
        },
    },
    created() {
        console.log('ProjectList created');
        this.isHistory = this.$route.query.isHistory;
        this.getProjects();
    },
};
</script>

<style scoped></style>
